.loadingText {
  position: relative;
}

.loading {
  position: absolute;
  top: 50%;
  left: -50px;

  transform: translate(0, -50%);
}
