.root {
  max-width: 325px;

  padding: 30px 20px 0;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
}

.logo {
  width: 24px;
  height: 24px;

  margin: 0 0 131px;

  display: block;
}

.title {
  margin: 0 0 40px;
}

.subTitle {
  margin: 0 0 20px;
}

.tryAgainButton {
  margin: 0 0 40px;
}
