.container {
  box-sizing: border-box;
  min-height: 100dvh;
  max-height: 100dvh;

  padding: 16px 0;
  overflow: hidden;

  display: grid;
  grid-template-rows: auto auto 1fr auto auto;

  background-color: var(--black-100);
}

.title_container {
  padding: 36px 20px 20px;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.title {
  color: var(--white-100);
  text-align: center;
  font-family: Urbanist, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.3px;
}

.blue_color {
  color: var(--primary-100);
}

.subtitle {
  padding: 3px 8px;
  border-radius: 6px;
  background-color: var(--white-50);
  backdrop-filter: blur(4px);
  color: var(--black-100);
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.img_container {
  position: relative;
  max-height: 100%;

  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome_img {
  position: absolute;
  width: auto;
  max-width: 600px;
  height: 400px;
  max-height: 100%;
}

.footer_container {
  display: grid;
  gap: 16px;
  max-width: 600px;
  margin: 16px auto;
  padding: 0 20px;
  box-sizing: border-box;
}

.continue_btn {
  background-color: var(--primary-100);
}

.agreement {
  box-sizing: border-box;

  padding: 0 12px;
  margin: auto;

  display: grid;
  align-items: center;
  grid-gap: 8px;
  grid-template-columns: auto 1fr;

  color: var(--white-100);

  font-weight: 700;
  font-size: 8px;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  line-height: normal;

  a {
    text-decoration: underline;
  }
}
