@import 'src/styles/media-queries';

.root {
  width: 100%;

  padding: 175px 17px 20px;

  position: relative;

  display: flex;
  justify-content: center;

  @include tiny-only {
    padding: 125px 17px 20px;
  }
}

.bg {
  width: 375px;
  height: 375px;

  overflow: hidden;

  position: absolute;
  top: 50px;
  left: 50%;
  z-index: 0;

  border-radius: 12px;

  transform: translate(-50%, 0);

  @include tiny-only {
    width: 100%;
    height: 100%;

    top: 0;

    border-radius: 0;
  }
}

.container {
  max-width: 341px;

  padding: 40px 18px 18px;

  position: relative;

  background: var(--white-100);

  border-radius: 15px;

  text-align: center;
}

.title {
  padding:0;
  margin: 0 0 12px;

  color: var(--black);

  font-weight: 400;
  font-size: 24px;
  font-family: 'Brygada 1918', sans-serif;
  font-style: italic;
  line-height: normal;
}

.titleBold {
  color: var(--grey-dark);

  font-weight: 700;
  font-size: 24px;
  font-family: 'Space Grotesk', sans-serif;
  font-style: normal;
  line-height: normal;

  text-align: center;
}

.subTitle {
  max-width: 234px;

  padding: 0;
  margin: 0 auto 32px;

  color: var(--black);

  font-weight: 400;
  font-size: 12px;
  font-style: normal;
  line-height: normal;

  text-align: center;
}

.bold {
  font-weight: 700;
}

.noCommitment {
  margin: 0 0 32px;

  color: var(--black);

  font-weight: 700;
  font-size: 12px;
  font-style: normal;
  line-height: normal;

  text-align: center;
}

.total {
  padding: 12px 0;
  margin: 0 0 12px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top: 1px solid var(--line-color);
  border-bottom: 1px solid var(--line-color);
}

.totalTitle {
  color: var(--grey-dark);

  font-weight: 700;
  font-size: 12px;
  font-style: normal;
  line-height: normal;

  text-align: center;
}

.cost {
  padding: 12px 0;
  margin: 0 0 32px;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  color: var(--grey-dark);

  border-bottom: 1px solid var(--line-color);

  font-weight: 500;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
}

.opacityText {
  opacity: 0.5;
}

.crossedText {
  text-decoration: line-through;
}

.costTitle {
  text-align: left;
}

.costAmount {
  text-align: right;
}

.submitButton {
  margin: 0 0 22px;

  display: flex;
  gap: 12px;
}

.creditCard {
  width: 22px;
  height: 16px;
}

.paypalButton {
  margin: 0 0 5px;
}

.overviewModal {
  height: 100vh;

  padding: 175px 20px 20px;

  position: absolute;
  z-index: 9999999;

  justify-content: flex-start;

  background: rgb(0 0 0 / 50%);

  @include tiny-only {
    padding: 125px 17px 20px;
  }
}
