.paymentModal {
  width: 304px;
  min-height: 547px;

  padding: 62px 18px 40px;

  position: relative;

  display: flex;
  flex-direction: column;

  text-align: center;
}

.title {
  padding: 0;
  margin: 0 0 20px;

  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: normal;

  text-align: center;
}

.closeButton {
  width: 30px;
  height: 30px;

  padding: 0;

  position: absolute;
  top: 16px;
  right: 18px;

  background: none;

  border: 0;

  cursor: pointer;
}

.cards {
  padding: 0;
  margin: 0 0 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  list-style: none;
  gap: 2px;
}

.card {
  width: 34.833px;
  height: 23px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 0.528px solid #717070;

  border-radius: 4.222px;
}

.cardImage {
  display: block;
}

.visa {
  width: 28.5px;
  height: 11px;
}

.mastercard {
  width: 13px;
  height: 10px;
}

.amex {
  width: 34.833px;
  height: 23px;
}

.maestro {
  width: 21.341px;
  height: 13.696px;
}

.discover {
  width: 21.341px;
  height: 11.087px;
}

.info {
  margin: 0 0 15px;

  font-size: 10px;
}

.divider {
  height: 1px;

  margin: 0 0 16px;

  background: #D9D9D9;
}

.loading {
  width: 100%;
  height: 547px;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--white-100);
}

.hidden {
  visibility: hidden;
}
