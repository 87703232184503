.info {
  margin: 0 auto 22px;

  opacity: 0.5;

  font-weight: 500;
  font-size: 10px;
  font-style: normal;
  line-height: normal;

  text-align: center;
}

.bold {
  font-weight: 700;
}
