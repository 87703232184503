.wallet_button {
  margin-bottom: 20px;
}

.divider {
  margin: 0 0 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: var(--grey-dark);

  font-weight: 700;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  gap: 8px;

  &::before, &::after {
    width: 100%;
    height: 1px;

    display: block;

    opacity: 0.5;

    background: var(--grey-dark);
    content: '';
  }
}

.variants {
  margin: 0 0 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.variantsTitle {
  color: var(--grey-dark);

  font-weight: 700;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
}

.cards {
  padding: 0;
  margin: 0;

  display: flex;
  align-items: center;

  list-style: none;
  gap: 1px;
}

.card {
  width: 23px;
  height: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 0.344px solid #717070;
  border-radius: 2.754px;
}

.base_number {
  padding: 15px 12px;

  grid-area: number;

  color: #1B1A1F;

  border: 1px solid #D8D8D8;
  border-radius: 6px;

  font-weight: 500;
  font-size: 14px;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  line-height: normal;

  &::placeholder {
    opacity: 0.5;
  }
}

.base_date {
  padding: 15px 12px;

  grid-area: date;

  color: #1B1A1F;

  border: 1px solid #D8D8D8;
  border-radius: 6px;

  font-weight: 500;
  font-size: 14px;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  line-height: normal;

  &::placeholder {
    opacity: 0.5;
  }
}

.base_code {
  padding: 15px 12px;

  grid-area: code;

  color: #1B1A1F;

  border: 1px solid #D8D8D8;
  border-radius: 6px;

  font-weight: 500;
  font-size: 14px;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  line-height: normal;

  &::placeholder {
    opacity: 0.5;
  }
}

.custom_form {
  padding: 20px 0;

  display: grid;
  grid-auto-columns: 1fr 1fr;
  grid-gap: 10px;
  grid-template-areas: 'number number'
  'date code';
}

.cardImage {
  display: block;
}

.visa {
  width: 18.587px;
  height: 7.174px;
}

.mastercard {
  width: 13px;
  height: 10px;
}

.maestro {
  width: 21.341px;
  height: 13.696px;
}

.discover {
  width: 21.341px;
  height: 11.087px;
}

.total {
  margin: 0 0 30px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: var(--grey-dark);

  font-weight: 700;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
}

.info {
  color: var(--grey-dark);

  font-weight: 500;
  font-size: 10px;
  font-style: normal;
  line-height: normal;

  text-align: center;
}

.payButton {
  margin: 0 0 22px;
}


.loadingText {
  position: relative;
}

.loading {
  position: absolute;
  top: 50%;
  left: -50px;

  transform: translate(0, -50%);
}
