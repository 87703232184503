.cookiesModal {
  max-width: 500px;

  padding: 15px 12px;

  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
}

.cookiesIcon {
  width: 46px;
  height: 46px;

  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;

  background-color: var(--gray);

  border-radius: 16px;
}

.cookiesIconImage {
  width: 24px;
  height: 24px;
}

.description {
  flex: 1 0 0;

  font-weight: 500;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-feature-settings: 'clig' off, 'liga' off;
}

.link {
  color: var(--grey-dark);
  text-decoration-line: underline;
}

.button {
  width: 86px;

  margin-left: 5px;
}

.closeButton,
.closeIcon {
  width: 30px;
  height: 30px;

  cursor: pointer;
}

.closeButton {
  position: absolute;
  bottom: -37px;
  left: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  background: none;

  border: 0;
  border-radius: 0;

  transform: translate(-50%, 0);
}
