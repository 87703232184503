.overlay {
  width: 100%;
  height: 100%;

  padding: 55px 16px;

  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--modal-z-index);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: linear-gradient(0deg, #030303 0%, rgb(3 3 3 / 0%) 50%);
}

.blur {
  background: rgb(0 0 0 / 50%);
  backdrop-filter: blur(6.5px);
}

.modal {
  overflow-y: scroll;

  background: var(--white-100);

  border-radius: 16px;
}

.center {
  justify-content: center;
}

.bottom {
  justify-content: flex-end;
}

