@import 'src/styles/media-queries';

.root {
  width: 100%;

  padding: 175px 17px 20px;

  position: relative;

  display: flex;
  justify-content: center;

  @include tiny-only {
    padding: 125px 17px 20px;
  }

}

.bg {
  width: 375px;
  height: 375px;

  overflow: hidden;

  position: absolute;
  top: 50px;
  left: 50%;
  z-index: 0;

  border-radius: 12px;

  transform: translate(-50%, 0);

  @include tiny-only {
    width: 100%;
    height: 100%;

    top: 0;
    left: 0;
    transform: translate(0, 0);

    border-radius: 0;
  }
}

.container {
  max-width: 341px;

  padding: 40px 18px 18px;

  position: relative;

  background: var(--white-100);

  border-radius: 15px;

  text-align: center;
}

.title {
  margin: 0 0 12px;
}

.subTitle {
  max-width: 234px;

  padding: 0;
  margin: 0 auto 38px;

  color: var(--black);

  font-weight: 400;
  font-size: 12px;
  font-style: normal;
  line-height: normal;

  text-align: center;
}

.bold {
  font-weight: 700;
}

.plans_addition_info {
  margin-top: 6px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-weight: 700;
  font-size: 10px;
  font-family: "Space Grotesk", sans-serif;

  .tax_info {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 6px 0;

    &::after {
      width: 14px;
      height: 14px;

      display: block;

      background-image: url("../../assets/images/trial/check.svg");
      background-size: contain;
      content: '';
    }
  }
}

.submitButton {
  margin: 0 0 22px;
}

.info {
  max-width: 285px;

  margin: 0 auto 22px;

  opacity: 0.5;

  font-weight: 500;
  font-size: 10px;
  font-style: normal;
  line-height: normal;

  text-align: center;
}

.link {
  color: var(--black);

  text-decoration: underline;
}

.label {
  width: 305px;
  height: 70px;

  padding: 0 10px 0 16px;

  position: relative;

  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  align-items: center;

  border: 1px solid #D8D8D8;
  border-radius: 12px;

  cursor: pointer;
}

.input[type="radio"] {
  display: none;
}

.input[type="radio"]:checked + .label {
  border: 1px solid var(--grey-dark);
  outline: 1px solid var(--grey-dark);
}

.popular {
  padding: 1px 4px;

  position: absolute;
  top: -7px;
  left: 16px;
  z-index: 1;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  color: var(--black);
  background: var(--yellow-light);

  border-radius: 2px;

  font-weight: 700;
  font-size: 8px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.56px;

  text-align: center;
  text-transform: uppercase;
  gap: 10px;
}

.amount {
  color: var(--grey-dark);

  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: normal;

  text-align: left;
}

.amountDescription {
  color: var(--grey-dark);
  text-align: center;
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.price {
  display: flex;
  align-items: center;

  color: var(--grey-dark);

  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: normal;

  text-align: right;
  gap: 6px;

  &::before {
    width: 1px;
    height: 49px;

    display: flex;

    opacity: 0.15;

    background: var(--grey-dark);
    content: '';
  }
}

.priceWrapper {
  padding: 6px;
}

.priceDescription {
  color: var(--grey-dark);

  font-weight: 400;
  font-size: 10px;
  font-style: normal;
  line-height: normal;

  text-align: center;
}

.formField {
  margin: 0 0 6px;

  &:last-of-type {
    margin: 0 0 32px;
  }
}
