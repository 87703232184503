@import "src/styles/media-queries";

.root {
  width: 100%;
  height: 100vh;
  max-width: 520px;

  padding: 30px 20px 0;
  margin: 0 auto;

  position: relative;

  display: flex;
  flex-direction: column;

  color: var(--grey-dark);

  @include medium {
    max-width: 350px;
  }
}

.questionCounter {
  margin: 0 0 2px;

  font-weight: 700;
  font-size: 10px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 5px;

  text-transform: uppercase;
}

.title {
  margin: 0 0 14px;
}

.questions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}

.question {
  padding: 0;
  overflow: hidden;

  position: relative;

  display: flex;

  background: none;

  border: 0;
  border-radius: 8px;

  cursor: pointer;
}

.questionText {
  padding: 6px 8px;

  position: absolute;
  bottom: 12px;
  left: 50%;

  color: var(--white-100);
  background: rgb(0 0 0 / 50%);

  border-radius: 6px;

  font-weight: 700;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;

  text-align: center;

  transform: translate(-50%, 0);
}

.activeQuestion {
  outline: 2px solid var(--blue);
}
