.modal_content {
  box-sizing: border-box;
  width: 100%;

  padding: 40px 20px 20px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.subtitle {
  color: var(--grey-dark);
  text-align: center;
  font-family: "Space Grotesk", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.blue_text {
  color: var(--primary-100);
}


.title {
  font-family: Urbanist, sans-serif;
}

.description {
  color: var(--grey-dark);

  font-weight: 700;
  font-size: 12px;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  line-height: normal;

  text-align: center;
}

.input_container {
  padding: 40px 0;
}

.input {
  width: 100%;
}

.input_description {
  margin-top: 12px;

  display: grid;
  align-items: center;
  grid-gap: 8px;
  grid-template-columns: auto 1fr;

  color: var(--black-50);

  font-weight: 500;
  font-size: 10px;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  line-height: normal;
}
