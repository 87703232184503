.root {
  width: 100%;
  max-width: 325px;

  padding: 30px 20px 0;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
}

.logo {
  width: 24px;
  height: 24px;

  margin: 0 0 131px;

  display: block;
}

.title {
  margin: 0 0 40px;
}

.checkIcon {
  width: 62px;
  height: 62px;

  margin: 0 0 40px;

  display: block;
}

.subtitle {
  margin: 0 0 20px;
}

.app_button {
  width: 100%;
  height: 50px;

  margin-bottom: 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--white-100);
  background-color: var(--grey-dark);

  border-radius: 12px;
  outline: none;

  font-weight: 700;
  font-size: 18px;

  cursor: pointer;
}


.copy_button {
  width: 100%;
  margin: 0 0 6px;
}

.text {
  margin: 0 0 6px;

  color: var(--black);

  font-weight: 400;
  font-size: 12px;
  font-style: normal;
  line-height: normal;

  text-align: center;
}
