.root {
  width: 100%;
  max-width: 500px;

  padding: 50px 20px 20px;
  margin: 0 auto;

  text-align: center;
}

.title {
  margin: 0 0 30px;
}

.paypalContainerButton {
  margin: 0 0 30px;
}

.divider {
  margin: 0 0 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: var(--grey-dark);

  font-weight: 700;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  gap: 8px;

  &::before, &::after {
    width: 100%;
    height: 1px;

    display: block;

    opacity: 0.5;

    background: var(--grey-dark);
    content: '';
  }
}

.loading {
  height: 420px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  visibility: hidden;
}
