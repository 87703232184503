@import "../../styles/media-queries";

.root {
  max-width: 100%;

  padding: 198px 40px 20px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  background-color: var(--white-100);

  text-align: center;

  @include medium {
    max-width: 500px;

    padding: 198px 20px 20px;
  }
}

.title {
  margin-top: 10px;
}

.subTitle {
  margin: 0 0 40px;

  color: var(--black);

  font-weight: 400;
  font-size: 12px;
  font-style: normal;
  line-height: normal;

  text-align: center;
}

.info {
  margin: 0 0 40px;

  display: flex;

  text-align: left;
  gap: 8px;
}

.infoImage {
  width: 17px;
  height: 20px;
}

.infoText {
  color: rgb(0 0 0 / 50%);

  font-weight: 500;

  font-size: 10px;
  font-style: normal;
  line-height: normal;
}

.input {
  width: 100%;

  margin: 0 0 10px;
}

.loadingText {
  position: relative;
}

.loading {
  position: absolute;
  top: 50%;
  left: -50px;

  transform: translate(0, -50%);
}
