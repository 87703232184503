.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}

.logo_icon {
  width: 26px;
}

.logo_label {
  color: var(--white-100);

  font-weight: 700;
  font-size: 10px;
  font-family: Arial, sans-serif;
  font-style: normal;
  line-height: normal;
  letter-spacing: 5px;

  text-align: center;
  text-transform: uppercase;
}
