.modal {
  max-width: 335px;

  padding: 20px;

  color: var(--gray-dark);
}

.header3 {
  padding: 0;
  margin: 0 0 10px;

  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: normal;

  text-transform: uppercase;
}

.paragraph {
  padding: 0;
  margin: 0 0 20px;

  color: var(--gray-dark);

  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  font-feature-settings: 'clig' off, 'liga' off;
}

.link {
  color: var(--grey-dark);

  text-decoration-line: underline;
}

.buttons {
  display: flex;
  gap: 10px;

  justify-content: space-between;
}
