@import "../../styles/media-queries";

.root {
  width: 100%;
  height: 100%;

  padding: 84px 20px 44px;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: var(--white-100);
  background-image: url('../../assets/images/welcome/welcome-page-mobile-bg.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include medium {
    padding: 80px 84px 62px;

    background-image: url('../../assets/images/welcome/welcome-page-desktop-bg.webp');
    background-repeat: repeat-x;
    background-size: auto 100%;
    background-position-x: 0;
  }

  &::before,
  &::after {
    width: 100%;

    position: absolute;
    left: 0;
    z-index: var(--bg-z-index);
    content: '';
  }

  &::before {
    height: 70%;

    top: 0;

    background: linear-gradient(180deg, rgb(0 0 0 / 81%) 33.72%, rgb(3 3 3 / 0%) 80.7%);
  }

  &::after {
    height: 40%;

    bottom: 0;

    background: linear-gradient(0deg, #030303 0%, rgb(3 3 3 / 0%) 100%);
  }
}

.header,
.footer {
  position: relative;
  z-index: var(--elements-z-index);
}

.footer {
  @include medium {
    max-width: 335px;
  }
}

.aiLogo {
  width: 22px;
  height: 18px;

  margin: 0 0 30px;
}

.description {
  max-width: 300px;
}

.info {
  display: block;

  font-weight: 500;
  font-size: 10px;
  font-style: normal;
  line-height: normal;

  text-align: center;
}

.arrowRight {
  width: 20px;
  height: 20px;
}

.button {
  margin: 0 0 14px;
}

.cookiesModal {
  overflow-y: inherit;
}

.link {
  color: inherit;
  font-weight: 700;
}
