.container {
  min-height: 100dvh;
  max-height: 100dvh;

  overflow: hidden;

  display: grid;
  grid-template-rows: auto 1fr auto;

  background-color: var(--black-100);
}

.logo_container {
  box-sizing: border-box;

  padding: 16px 20px;
}

.slide_title {
  color: var(--white-100);

  font-weight: 700;
  font-size: 32px;
  font-family: Urbanist, sans-serif;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;

  text-align: center;
}

.slide_description {
  color: var(--white-100-white, #FFF);
  text-align: center;
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.blue_text {
  color: var(--primary-100);
}

.slide {
  max-height: 100%;
  overflow: hidden;
}

.slide_hidden {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.button_container {
  box-sizing: border-box;

  padding: 16px 20px;
}

.continue_btn {
  max-width: 600px;
  margin: auto;

  background-color: var(--primary-100);
}

.slider_dots_container {
  padding: 5px 0 20px;

  display: flex;
  justify-content: center;
}

.slider_dots {
  padding: 8px;

  display: flex;
  align-items: center;

  background-color: var(--white-10);

  border-radius: 22px;
  gap: 8px;
}

.slider_dot {
  width: 8px;
  height: 8px;

  background-color: var(--white-100);

  border-radius: 6px;

  transition: width .3s;
}

.slider_dot_active {
  width: 34px;
}
