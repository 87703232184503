.container {
  height: 100%;
  max-height: 100%;

  overflow: hidden;

  display: grid;
  grid-template-rows: auto 1fr auto;
}

.title {
  box-sizing: border-box;

  padding: 20px;
}

.img_container {
  position: relative;
  max-height: 100%;

  margin: 16px 0 24px;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}

.img {
  position: absolute;
  width: auto;
  max-width: 600px;
  height: 400px;
  max-height: 100%;
}
