@import "../../styles/media-queries";

.root {
  width: 100%;
  height: 100%;

  padding: 0 32px 82px;
  overflow-x: hidden;

  position: relative;

  background-color: var(--black);

  text-align: center;

  &::after {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;

    left: 0;
    z-index: 1;

    background: linear-gradient(180deg, rgb(0 0 0 / 81%) 33.72%, rgb(3 3 3 / 0%) 80.7%);
    content: '';
  }

  &::before {
    width: 600px;
    height: 600px;

    margin-left: -300px;

    position: absolute;
    top: 106px;
    left: 50%;
    z-index: 0;

    background: url('../../assets/images/generate/generate-bg.webp');
    background-size: cover;
    content: '';

    @include tiny-only {
      top: -17px;
    }
  }
}

.title {
  margin: 295px 0 30px;

  position: relative;
  z-index: 2;

  color: var(--white-100);

  font-weight: 700;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 5px;

  text-transform: uppercase;

  @include tiny-only {
    margin: 170px 0 30px;
  }
}

.type {
  max-width: 400px;

  margin: 0 auto 170px;

  position: relative;
  z-index: 2;

  color: var(--white-100, #FFF);

  font-weight: 700;
  font-size: 34px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;

  @include tiny-only {
    font-size: 30px;
  }
}

.loading {
  max-width: 400px;
}
